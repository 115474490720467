import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"
import { Container, Row, Col } from "reactstrap"
import styles from "../pages/css/index.module.css"

export default ({data}) => {
  const content = data.craft.content[0];
  var errorStyle = {
    paddingBottom: '300px',
    marginBottom: '-150px'
  }
  return (
    <div>
      <Header/>
      <div className={styles.hero}>
        <Container>
          <Row>
            <Col xs="12" md="8">
              <div className={styles.heroContent}>
                <h1>{content.mainContentHeadline}</h1>
                <div dangerouslySetInnerHTML={{ __html: content.mainContent }} style={errorStyle}></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer style={errorStyle}/>
    </div>
  )
}

export const query = graphql`
  query ErrorPage {
    craft {
      content: entries(siteId: "4", id: 147, status: "disabled") {
        ... on craft_errorPage_errorPage_Entry {
          id
          status
          mainContentHeadline
          mainContent
        }
      }
    }
  }
`;